// 账户流水明细
<template>

    <div class="accountInfoDetails">
        <div class="baseBg">
            <div class="listBox mainWhiteBg">
                <div class="title"><span @click="toAllCount"
                          class="cup">全部账户</span> <i class="el-icon-arrow-right"></i> <span>{{$route.query.name}}</span></div>
                <div class="tableBox">
                    <div class="greySearchBar">
                        <el-form :model="searchForm"
                                 ref="searchForm"
                                 :rules="searchFormRules"
                                 :inline="true">

                            <el-form-item label="收入编号"
                                          prop="code">
                                <el-input v-model="searchForm.code"
                                          placeholder="请输入收入编号"
                                          clearable />
                            </el-form-item>
                            <el-form-item label="客户"
                                          prop="companyId">
                                <cust-sup-choose placeholderStr="请选择客户/供应商"
                                                 :showSeeProvider="false"
                                                 :disable="true"
                                                 :shouldClean="true"
                                                 :isScorll="true"
                                                 @handClear="clearProvider"
                                                 :cleanProvider="cleanProvider"
                                                 @treeSelectNodeClick="customerClick"></cust-sup-choose>
                            </el-form-item>
                            <el-form-item label="类型"
                                          prop="type">
                                <el-select v-model="searchForm.type"
                                           placeholder="请选择类型"
                                           clearable>
                                    <el-option label="流入"
                                               value="FLOW_INTO"></el-option>
                                    <el-option label="流出"
                                               value="FLOW_OUT"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="日期"
                                          prop="timeArr">
                                <el-date-picker v-model="timeArr"
                                                type="daterange"
                                                value-format="yyyy-MM-dd"
                                                range-separator="至"
                                                placeholder="请选择日期"
                                                start-placeholder="开始日期"
                                                end-placeholder="结束日期">
                                </el-date-picker>
                            </el-form-item>
                            <el-button type="primary"
                                       @click="getAccountInfoDetails(1)">搜索</el-button>
                            <el-button @click="resetSearchForm">清除</el-button>
                        </el-form>
                    </div>
                </div>

                <!-- 控制条 -->
                <div class="greySearchBar control">
                    <com-export-table-to-excel :searchForm="Object.assign({}, this.searchForm, {
                                    accountId: this.$route.query.id || null,
                                    page: page || this.searchForm.page,
                                })"></com-export-table-to-excel>
                </div>

                <div class="tableBox">
                    <el-table :data="tableData.data"
                              border
                              :height="tableHeight"
                              stripe>
                        <el-table-column label="序号"
                                         type="index"
                                         align="center"
                                         width="60">
                        </el-table-column>
                        <el-table-column label="明细日期"
                                         prop="createTime"
                                         align="center"
                                         width="auto"
                                         min-width="155">
                        </el-table-column>
                        <!-- TODO 点击打开对应单据
                                    1、付款记录单据
                                    2、收款记录单据
                                    3、资金调拨单据 -->
                        <el-table-column label="明细编号"
                                         prop="recordCode"
                                         align="center"
                                         width="auto"
                                         min-width="155">
                        </el-table-column>
                        <el-table-column label="类型"
                                         prop="receivingType.text"
                                         align="center"
                                         width="auto"
                                         min-width="155">
                        </el-table-column>
                        <el-table-column label="客户/供应商"
                                         prop="companyName"
                                         align="center"
                                         width="auto"
                                         min-width="155">
                        </el-table-column>
                        <el-table-column label="对方账户"
                                         prop="reciprocalAccount"
                                         align="center"
                                         width="auto"
                                         min-width="155">
                        </el-table-column>
                        <el-table-column label="备注"
                                         prop="remark"
                                         align="center"
                                         width="auto"
                                         min-width="155">
                        </el-table-column>
                        <el-table-column label="流入"
                                         prop="inAmount"
                                         align="center"
                                         width="auto"
                                         min-width="155">
                        </el-table-column>
                        <el-table-column label="流出"
                                         prop="outAmount"
                                         align="center"
                                         width="auto"
                                         min-width="155">
                        </el-table-column>
                        <el-table-column label="余额（元）"
                                         prop="balance"
                                         align="center"
                                         width="auto"
                                         min-width="155">
                        </el-table-column>
                    </el-table>
                    <div class="pageFooter">
                        <!-- 金额统计 -->
                        <amount-statistical type="资金管理明细"
                                            :totalData="statistical"></amount-statistical>
                        <!-- 分页 -->
                        <Pagination :page="searchForm.page"
                                    :limit="searchForm.size"
                                    :total="pageTotal"
                                    @pagination="paginationChange" />
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import AmountStatistical from "../../components/amountStatistical/amountStatistical";
import ComExportTableToExcel from "../../components/export/comExportTableToExcel";
import CustSupChoose from "../../components/selectTree/custSupChoose";
import Pagination from "@/components/Pagination"; // 分页
import { Loading } from "element-ui";
import API from "@/api/finance.js";
export default {
    name: "accountInfoDetails",

    props: {},

    components: {
        AmountStatistical,
        ComExportTableToExcel,
        CustSupChoose,
        Pagination,
    },

    data() {
        return {
            searchForm: {
                queryFlag: "1", // tabs 1=待审批,2=已审批,3=我发起的,4=抄送我的
                name: "", //合同名称
                companyId: "", //客户或供应商id
                type: "", //合同类型
                deptId: "", //部门id
                page: 1,
                size: 20,
            },
            timeArr: ["", ""], // 筛选时间范围
            searchFormRules: {},
            pageTotal: 0,
            cleanProvider: null,
            tableData: {
                data: [],
                columns: [
                    { id: "projectName", label: "关联项目", width: "100" },
                    { id: "companyName", label: "客户", width: "100" },
                    { id: "totalAmount", label: "金额（元）", width: "100" },
                    { id: "deptName", label: "部门", width: "100" },
                    { id: "chargeUserName", label: "负责人", width: "100" },
                    { id: "createTime", label: "新增日期", width: "100" },
                ],
            },
            statistical: null, // 统计信息
        };
    },

    created() {},

    mounted() {
        // 查询资金账户流水
        this.getAccountInfoDetails();
    },

    methods: {
        // 查询资金账户流水
        getAccountInfoDetails(page) {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            let data = Object.assign({}, this.searchForm, {
                accountId: this.$route.query.id || null,
                page: page || this.searchForm.page,
            });
            API.getAccountInfoDetails(data)
                .then((res) => {
                    loading.close();
                    this.tableData.data = res.content.records;
                    this.pageTotal = res.content.total;
                    this.statistical = res.content.statistical;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 分页改变
        paginationChange(e) {
            this.searchForm.size = e.size;
            this.searchForm.page = e.page;
            this.getAccountInfoDetails();
        },
        // 清空表单
        resetSearchForm() {
            this.$nextTick(() => {
                if (this.$refs["searchForm"] !== undefined) {
                    this.$refs["searchForm"].resetFields();
                    this.timeArr = ["", ""];
                    // 客户的清空
                    this.clearProvider();
                    // 查询列表
                    this.getAccountInfoDetails();
                }
            });
        },
        // 返回账户总览
        toAllCount() {
            this.$router.push({
                path: "/moneyManage/account",
            });
        },
        // 客户的选择
        customerClick(node) {
            this.searchForm.companyId = node.id;
        },
        // 客户的清空
        clearProvider() {
            this.searchForm.companyId = "";
            this.cleanProvider = Math.random();
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 345 });
        },
    },

    watch: {
        timeArr() {
            this.searchForm.beginDate = this.timeArr[0] || null;
            this.searchForm.endDate = this.timeArr[1] || null;
        },
    },
};
</script>

<style lang="scss" scoped>
.accountInfoDetails {
    .title {
        margin-bottom: 20px;
        span,
        i {
            margin-right: 8px;
        }
    }
}
</style>
